import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kernel-koans",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel Koans`}<a parentName="h1" {...{
        "href": "#kernel-koans",
        "aria-label": "kernel koans permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><em parentName="p">{`Boundless wind and moon--the eye within eyes,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Inexhaustible heaven and earth--the light beyond light,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The willow dark, the flower bright--ten thousand houses;`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Knock at any door--there's one who will respond.`}</em>{`  `}</p>
    <p>{`Koans come from the zen tradition. They are seemingly simple questions which, at first blush, make no sense. "What is the sound of one hand clapping?" or "When the tree withers and the leaves fall, then what?"`}</p>
    <p>{`Koans are a type of lens aimed at short-circuiting our map-making habits so that we may arrive at a fuller experience of reality. If opened within, koans can reveal what is by asking over and over questions we cannot answer except with our whole being.`}</p>
    <p>{`Thus we arrive at the `}<em parentName="p">{`Genjo Koan`}</em>{`: your own life, for that which is manifesting before your eyes right now is absolute reality. Ikkyu, our `}<a parentName="p" {...{
        "href": "/learn/module-4/self-enquiry"
      }}>{`friend from module four`}</a>{`, sums up all this nonsense masterfully:`}</p>
    <p><em parentName="p">{`Only one koan matters`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`You`}</em>{`  `}</p>
    <h2 {...{
      "id": "the-universal-library",
      "style": {
        "position": "relative"
      }
    }}>{`The Universal Library`}<a parentName="h2" {...{
        "href": "#the-universal-library",
        "aria-label": "the universal library permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Koans are a means by which the master is able to learn whether the student has figured out who the teacher really is. Can you see yet - and are you living - the total lack of difference between `}<em parentName="p">{`lehren`}</em>{` and `}<em parentName="p">{`lernen`}</em>{`? `}</p>
    <p>{`Either way, we need a similarly creative means of examining the breadth and depth of Kernel peers' conscious experience of their world, without being able to sit and breathe together in person. Without the age-old meaningful meeting between which might open the door for both into this single empty form, where can we find such a means?`}</p>
    <p>{`The only way is through `}<Link to="https://libraryofbabel.info" mdxType="Link">{`the universal library`}</Link>{`. `}</p>
    <p>{`Every word you could possibly write is already in there: the `}<Link to="https://libraryofbabel.info/bookmark.cgi?bitcoin:3" mdxType="Link">{`bitcoin whitepaper`}</Link>{`, the `}<Link to="https://libraryofbabel.info/bookmark.cgi?genesis" mdxType="Link">{`bible`}</Link>{`, the `}<Link to="https://libraryofbabel.info/bookmark.cgi?playwithpattern" mdxType="Link">{`Kernel syllabus`}</Link>{`, the true story of your own death, the Universal Declaration of Peace, every poem, every love song, every sick note you ever handed in to try and skip school because they never taught you how to truly learn... Literally everything that could ever be strung together is already there. The problem, of course, is finding the right signal in such an infinite array of noise.`}</p>
    <p><em parentName="p">{`Docere et delectare`}</em>{`: to teach and delight. That is our goal. And, strange though it seems, all the best, most creative, insightful, and delightful means of doing so are already in the Library. Yet we still have to write them out so that you can solve them...`}</p>
    <h2 {...{
      "id": "week-0---conversation",
      "style": {
        "position": "relative"
      }
    }}>{`Week 0 - Conversation`}<a parentName="h2" {...{
        "href": "#week-0---conversation",
        "aria-label": "week 0   conversation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "changing-solved",
      "style": {
        "position": "relative"
      }
    }}>{`Changing (Solved)`}<a parentName="h3" {...{
        "href": "#changing-solved",
        "aria-label": "changing solved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p><strong parentName="p">{`Note:`}</strong>{` being "solved" just means the NFT prize has already been allocated. You can (and should!) still try to solve this for yourself as a way of becoming more familiar with the Learn Track course content.`}</p>
    <p>{`We'll start simply. Anyone who is able to `}<Link to="https://libraryofbabel.info/bookmark.cgi?truthseeds" mdxType="Link">{`solve our series of riddles`}</Link>{` - based on the content from `}<a parentName="p" {...{
        "href": "/learn/module-0"
      }}>{`week 0`}</a>{` - will become the steward of `}<Link to="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/43060612980525300798945617690241154227807202326827270128619443670706517180417" mdxType="Link">{`this one-of-a-kind NFT`}</Link>{`, which blends the `}<em parentName="p">{`I Ching`}</em>{` hexagrams with a circular representation of our genetic code. All further instructions are to be found in the Library itself.`}</p>
    <Aligner center mdxType="Aligner">
      <Image style={{
        "width": "50%"
      }} src="/images/koans/i_ching_genetic_code.jpeg" alt="Random Mutations?" mdxType="Image" />
    </Aligner>
    <h3 {...{
      "id": "sparking-unsolved",
      "style": {
        "position": "relative"
      }
    }}>{`Sparking (Unsolved)`}<a parentName="h3" {...{
        "href": "#sparking-unsolved",
        "aria-label": "sparking unsolved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`It seems we started too simply, and the first koan was `}<Link to="https://twitter.com/youjustwin42/status/1348825535812571136" mdxType="Link">{`solved`}</Link>{` within a day of being made public. Which is wonderful, because now we get to make more. Here is `}<Link to="https://libraryofbabel.info/bookmark.cgi?truthsparks" mdxType="Link">{`the next series of riddles`}</Link>{` for you intrepid explorers of the infinite. This time, `}<Link to="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/43060612980525300798945617690241154227807202326827270128619443671806028808193" mdxType="Link">{`the reward`}</Link>{` is an unpublished poem-as-NFT which will guide you to the beginning of a rabbit hole about The Land of Nowhere.`}</p>
    <p>{`You will need three special words to help solve this: kaulayaw, eunoia, rasasvada.`}</p>
    <aligner {...{
      "center": ""
    }}>
      <img parentName="aligner" {...{
        "src": "/images/koans/na_kuja_abad_nft.svg",
        "alt": "Na Kuja Abad"
      }}></img>
    </aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      